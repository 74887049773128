import { ReactElement } from "react";
import SignInLayout from "@/components/Layouts/SignInLayout";
import SignIn from "@/components/SignIn";
import Head from "next/head";
import { NextPageWithLayout } from "@/lib/types";

const Login: NextPageWithLayout = (props) => {
  return (
    <>
      <Head>
        <title>Sign In</title>
      </Head>
      <SignIn />
    </>
  );
};

Login.getLayout = function getLayout(page: ReactElement) {
  return <SignInLayout>{page}</SignInLayout>;
};

export default Login;
