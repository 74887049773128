
import NextLink from "next/link"
import {
  Box,
  useColorModeValue,
  Text,
  Flex,
  Link,
  HStack,
  FormErrorMessage,
  FormControl,
  InputRightElement,
  InputGroup,
  Input,
  FormLabel,
  Alert,
  Button,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useRouter } from "next/router";
import { useState } from "react";
import { useAppDispatch } from "./../../lib/hooks";
import Image from "next/image";
import { useLoginMutation, useLazyGetUserDetailsQuery, useLazyGetClientsQuery, useLogoutMutation } from "@/lib/services/authApi";
import { GetBreakpoint, IsLargeOrBigger, isMobile } from "../utils/common";
import { IBreakpoint } from "@/lib/interfaces";
import { AppPayload } from "@/lib/types";
import circleLogo from './../../public/images/static/circleLogo.svg';
import skyscrapers from './skyscrapers.png';
import bgLogo from './bgLogo.svg';
import { decode } from "jsonwebtoken";
import { setUser, setAccessToken } from "@/lib/reducers/user";
import { setSkip } from "@/lib/reducers/apiData";

const SignIn = () => {
  const router = useRouter();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const dispatch = useAppDispatch();
  const [loginMutation] = useLoginMutation();
  const [userDetailsQuery] = useLazyGetUserDetailsQuery();
  const [getClients] = useLazyGetClientsQuery();
  const [logout] = useLogoutMutation();

  interface Values { email: string; password: string; }

  const bg = useColorModeValue('#ffffff', '#1a202c')
  const text = useColorModeValue('#232323', '#ffffff')

  const signInFormik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        // .email("Invalid email address")
        .required("E-mail is required."),
      password: Yup.string()
        .required("Password is required.")
        .min(3, "Password is too short - should be 3 chars minimum."),
    }),
    onSubmit: async ({ email, password }: Values) => {
      setLoading(true);

      try {
        const { accessToken } = await loginMutation({
          email,
          password,
        }).unwrap();

        const decodedToken: AppPayload = decode(accessToken) as AppPayload;
        if (decodedToken?.token) {
          const { data: userData } = await userDetailsQuery({ accessToken: decodedToken.token })
          const { data: clientsData } = await getClients({ accessToken: decodedToken.token })

          if (clientsData?.data?.length === 0) {
            //remove token - logout
            setLoading(false);
            setErrorMessage("Cannot login. Please contact your administrator.");
            await logout()
            return
          }


          if (userData?.status === 'success') {
            setErrorMessage("");

            dispatch(
              setUser({
                user: userData.data,
              })
            );
            dispatch(
              setAccessToken({
                accessToken,
              })
            );
          }
        }
        dispatch(setSkip({ skip: false }));

        const pathname = localStorage.getItem('pathname');
        //last visited page
        if (pathname != null) {
          router.push(pathname);
        } else {
          router.push("/dashboard");
        }
      } catch (err) {
        setLoading(false);
        setErrorMessage("Invalid credentials");
      }

    },
  });
  const [show, setShow] = useState<boolean>(false);
  const handleClick = () => setShow(!show);
  const breakpoint: IBreakpoint = GetBreakpoint() as IBreakpoint;

  return (
    <Flex bg={bg} w='100%' h='100vh' justifyContent={'space-between'}>
      <Flex
        w={IsLargeOrBigger(breakpoint) ? '50%' : '100%'}
        direction={'column'}
        alignItems='flex-start'

        pt={'15%'}
        px={isMobile(breakpoint) ? 10 : 20}
      >

        <NextLink href="/login" passHref>

          <Image
            style={{
              cursor: 'pointer', minWidth: `${circleLogo.width}px`,
              minHeight: `${circleLogo.height}px`
            }}
            src={circleLogo.src}
            width={circleLogo.width}
            height={circleLogo.height}
            alt="Logo"
          />

        </NextLink>



        <Text my={3} color={text} fontSize={isMobile(breakpoint) ? '2xl' : '5xl'} fontWeight={'bold'} >Welcome back</Text>
        <Text color='#8F8F8F'>Welcome back to <span style={{ 'fontWeight': 'bold' }}>Omni</span> Digital Building Management!<br />
          Please enter your details. </Text>
        <form
          style={{
            width: "100%",
          }}
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            signInFormik.handleSubmit(e);
          }}
        >
          <FormControl
            my={6}
            id="email"

            isInvalid={
              signInFormik.errors.email !== "" &&
              signInFormik.errors.email !== undefined
            }
          >
            <FormLabel fontWeight="bold" htmlFor="email" fontSize="sm">
              Email
            </FormLabel>
            <Input
              autoFocus={true}
              value={signInFormik.values.email}
              onChange={signInFormik.handleChange}
              name="email"
              disabled={loading}
              type="email"
              autoComplete="email"
            />
            <FormErrorMessage>{signInFormik.errors.email}</FormErrorMessage>
          </FormControl>

          <FormControl
            id="password"
            isInvalid={
              signInFormik.errors.password !== "" &&
              signInFormik.errors.password !== undefined
            }
          >        <FormLabel fontWeight="bold" htmlFor="email" fontSize="sm">
              Password
            </FormLabel>
            <InputGroup>
              <Input
                disabled={loading}
                value={signInFormik.values.password}
                onChange={signInFormik.handleChange}
                name="password"
                pr="4.5rem"
                type={show ? "text" : "password"}
                placeholder="Enter password"
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={handleClick}>
                  {show ? "Hide" : "Show"}
                </Button>
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>{signInFormik.errors.password}</FormErrorMessage>
          </FormControl>

          <Flex my={5} w='100%' justifyContent={'flex-end'} >
            <NextLink href='/passwordReset' passHref legacyBehavior>
              <Link color={text} fontWeight={'bold'} >Forgot password</Link>
            </NextLink>
          </Flex>
          <Alert variant={'top-accent'} my={5} status="error"
            display={errorMessage === "" ? "none" : "block"}
          >
            <HStack>
              <Box pr={1} color={"red.600"}>
                <FontAwesomeIcon icon={faExclamationCircle} />
              </Box>
              <Text>{errorMessage}</Text>
            </HStack>
          </Alert>

          <Button
            type="submit"
            size="lg"
            fontSize="md"
            isLoading={loading}
            color="white"
            bg="accentColor"
            h="48px"
            w='100%'
            _hover={{ opacity: 0.9 }}
            _active={{ opacity: 0.8 }}
          >
            Log in
          </Button>

          <Flex my={5} w='100%' justifyContent={'center'} display='none'>
            <Text color='#8F8F8F' > Don’t have an account?</Text>

            <NextLink href='/signup' passHref legacyBehavior>
              <Link ml={1} color={text} fontWeight={'bold'}>Sign Up</Link>
            </NextLink>

          </Flex>

        </form>
      </Flex>
      <Flex
        display={IsLargeOrBigger(breakpoint) ? 'flex' : 'none'}
        w='50%' bg='blue.300'>
        <Box
          w='100%'
          h='100%'
          position={'relative'}>
          <Image

            priority
            alt='Omni Sign In'
            src={skyscrapers.src}
            fill={true}
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
          />

          <Text top='45%' left={'45px'} position={'absolute'} color='#ffffff' zIndex={1111} fontSize={'3xl'} fontWeight='bold'>
            Bring your Buildings to Life<br />
            Digital Building Management
          </Text>

          <Box
            position={'absolute'}
            right='45px'
            top={'60%'}
          >
            <Image
              alt='bgLogo'
              src={bgLogo.src}
              width={bgLogo.width}
              height={bgLogo.height}
            />
          </Box>
        </Box>
      </Flex>
    </Flex >
  );
};

export default SignIn;
